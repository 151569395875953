<template>
  <div>
    <div class="cont-main" v-loading="loadings">
      <!-- 输入框部分 -->
      <el-form ref="ruleFormRef" :model="ruleForm" label-width="110px" label-position="right" :inline="true"
        class="demo-ruleForm col-333 lable-110">
        <el-form-item label="透后体重：">
          <el-input clearable @input="weightInput" v-model="ruleForm.after_weight" placeholder="请输入">
            <template #append>kg</template>
          </el-input>
        </el-form-item>
        <el-form-item class="lstwo" label="实际时长：">
          <el-input clearable disabled v-model="ruleForm.actual_touxi_hour" placeholder="请输入">
            <template #append>时</template>
          </el-input>
          <el-input clearable disabled v-model="ruleForm.actual_touxi_min" placeholder="请输入">
            <template #append>分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="机显脱水：">
          <el-input clearable v-model="ruleForm.screen_actual_warter" placeholder="请输入" @input="setWeight">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
        <el-form-item label="总输液量：">
          <el-input clearable v-model="ruleForm.total_flush_water" placeholder="请输入" @input="setWeight">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
        <el-form-item label="下机回水量：">
          <el-input clearable v-model="ruleForm.xj_huiwater" placeholder="请输入" @input="setWeight">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
        <el-form-item label="实际脱水量">
          <el-input disabled v-model="ruleForm.real_water" placeholder="请输入">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="title flex-start" v-if="ruleForm.type === 3">
        <div class="jniop"></div>
        <div class="juliss">内瘘评估</div>
      </div>
      <el-form ref="ruleForm1Ref" :model="ruleForm" :rules="Rule.SUMMARY" label-width="110px" label-position="right"
        :inline="true" class="demo-ruleForm col-333 lable-110" v-if="ruleForm.type === 3">
        <el-form-item label="震颤：">
          <el-select clearable v-model="ruleForm.tremor" placeholder="请选择">
            <el-option label="无" value="">
            </el-option>
            <el-option v-for="item in dictList[200000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div class="title flex-start" v-if="ruleForm.type === 3 || ruleForm.type === 2">
        <div class="jniop"></div>
        <div class="juliss">阻塞评估</div>
      </div>
      <el-form ref="ruleForm1Ref" :model="ruleForm" :rules="Rule.SUMMARY" label-width="110px" label-position="right"
        :inline="true" class="demo-ruleForm col-333 lable-110" v-if="ruleForm.type === 3 || ruleForm.type === 2">
        <el-form-item label="透析器阻塞：">
          <el-select clearable v-model="ruleForm.dict_touxiqi_zuse" placeholder="请选择">
            <el-option label="无" value="">
            </el-option>
            <el-option v-for="item in dictList[640000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="体外循环血路管阻塞：" label-width="160px">
          <el-select clearable v-model="ruleForm.dict_xuelvqi_zuse" placeholder="请选择">
            <el-option label="无" value="">
            </el-option>
            <el-option v-for="item in dictList[650000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="title flex-start" v-if="ruleForm.type === 2">
        <div class="jniop"></div>
        <div class="juliss">导管评估</div>
      </div>
      <el-form :model="ruleForm" label-width="110px" label-position="right" :inline="true"
        class="demo-ruleForm col-333 lable-110" v-if="ruleForm.type === 2">
        <el-form-item label="封管用药：">
          <el-select clearable v-model="ruleForm.pipe_drug" placeholder="请选择" @change="handlePipeDrugChange">
            <el-option v-for="item in dictList[204000000]" :key="item.id" :label="item.name"
              :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="动脉端封管液量">
          <el-input clearable v-model="ruleForm.dongmai_val" placeholder="请输入">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
        <el-form-item label="静脉端封管液量">
          <el-input clearable v-model="ruleForm.jingmai_val" placeholder="请输入">
            <template #append>ml</template>
          </el-input>
        </el-form-item>
      </el-form>

      <div class="title flex-start">
        <div class="jniop"></div>
        <div class="juliss">透后小结</div>
      </div>
      <el-form :model="ruleForm" label-width="110px" label-position="right" :inline="true"
        class="demo-ruleForm col-333 lable-110">
        <el-form-item class="addr but" label="护理小结：">
          <el-input v-model="ruleForm.summary" type="textarea" :rows="3" placeholder="请输入"></el-input>
        </el-form-item>
        <div class="templateBut">
          <el-button @click="generateSummary" :loading="saveLoading" class="color-determine" type="primary">生成小结
          </el-button>
          <el-button @click="openTemplate(1)" class="color-determine tip" type="primary">使用模板
          </el-button>
        </div>
        <el-form-item label="护士签名：">
          <el-col :span="11">
            <el-select clearable v-model="ruleForm.zeren_id" placeholder="请选择">
              <el-option v-for="item in userInfoHS" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="2"></el-col>
          <el-col :span="11">
            <el-button v-if="ruleForm.zeren_id == '' || ruleForm.zeren_id == null || ruleForm.zeren_id == undefined" class="color-main"
                       type="primary" @click="changeValue('zeren_id', 'zeren_id_name', '1')">
              确认护士
            </el-button>
            <el-button v-else class="color-main" type="primary" @click="changeValue('zeren_id', 'zeren_id_name', '2')">
              取消护士
            </el-button>
          </el-col>
        </el-form-item>
      </el-form>
      <el-form :model="ruleForm" label-width="110px" label-position="right" :inline="true"
        class="demo-ruleForm col-333 lable-110">
        <el-form-item class="addr but" label="医师记录：">
          <el-input v-model="ruleForm.doctor_summary" type="textarea" :rows="3" placeholder="请输入"></el-input>
        </el-form-item>
        <div class="templateBut">
          <el-button @click="generateSummary" :loading="saveLoading" class="color-determine" type="primary">生成小结
          </el-button>
          <el-button @click="openTemplate(2)" class="color-determine tip" type="primary">使用模板
          </el-button>
        </div>
        <el-form-item label="医生签名：">
          <el-col :span="11">
            <el-select clearable v-model="ruleForm.doctor_id" placeholder="请选择">
              <el-option v-for="item in userInfoYS" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="2"></el-col>
          <el-col :span="11">
            <el-button v-if="ruleForm.doctor_id == '' || ruleForm.doctor_id == null || ruleForm.doctor_id == undefined"
                       class="color-main" type="primary" @click="changeValue('doctor_id', 'doctor_id_name', '1')">
              确认医生
            </el-button>
            <el-button v-else class="color-main" type="primary" @click="changeValue('doctor_id', 'doctor_id_name', '2')">
              取消医生
            </el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </div>

    <!-- 保存按钮 -->
    <div class="fiall">
      <div class="warings" v-if="searchData.record_id != 0" @click="disinfectRecordOpen">
        <template v-if="machine_status == 1">已消毒</template>
        <template v-else>
          消毒
          <br />记录
        </template>
      </div>
      <div class="warings" @click="estimateSave" style="background: #0057bb">
        保存
      </div>
    </div>

    <!-- 使用模板弹窗 -->
    <BaseDialog title="透后小结模板选择" :isshow="bodyvlus" @handleShow="(val) => {
        bodyvlus = val
      }
      " width="1000px">
      <!-- 表格 -->
      <div style="margin-top: 20px">
        <el-table v-loading="tableLoading" :data="tableData" size="small" border height="350">
          <el-table-column width="200" prop="tem_name" label="模板名称" />
          <el-table-column prop="tem_show" label="模板内容" />
          <el-table-column fixed="right" label="操作" width="100">
            <template #default="scope">
              <el-button type="text" size="small" @click="useTemplate(scope.row)">使用
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页 -->
      <div class="pagination-box">
        <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum" @size-change="handleSizeChange"
          @current-change="handleCurrentChange"></el-pagination>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button class="color-cancel" type="primary" @click="bodyvlus = false">取消
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <BaseDialog title="pdf预览" :isshow="isPdf" @handleShow="(val) => {
        isPdf = val
      }
      " width="1000px">
      <!-- 表格 -->
      <div style="margin-top: 20px">
        <div class="pdfBox">
          <iframe :src="pdfURL" style="height: 100%; width: 100%"></iframe>
        </div>
      </div>
    </BaseDialog>
    <disinfectRecord v-if="isViewDialog" ref="disinfectRecordRef" @refreshDataList="refreshData"></disinfectRecord>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, watch } from 'vue'
import Rule from '@/utils/rule'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import { gitDictList } from '@/utils/tool'
import BaseDialog from '@/components/Dialog/index.vue'
import disinfectRecord from './disinfectRecord.vue'

export default {
  components: {
    BaseDialog,
    disinfectRecord,
  },
  props: ['patientData'],
  emits: ['stepsUpdate'],
  setup(props, ctx) {
    // const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      dictList: {},
      ruleForm: {},
      machine_status: '',
      loadings: false,
      Rule: Rule,
      ruleForm1Ref: null,
      patientId: '14',
      userNameList: [],
      saveLoading: false,
      searchDatas: {},
      tableLoading: false,
      bodyvlus: false,
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      isViewDialog: false,
      disinfectRecordRef: null,
      searchData: {
        patient_id: 0,
        record_id: 0,
      },
      autograph: {
        huixue_id: '',
        xunshi_id: '',
        zeren_id: '',
        chadui_id: '',
        caji_id: '',
        doctor_id: '',
      },
      pdfURL: '',
      isPdf: false,
      doctorUserList: [],
      userInfoHS: [],
      userInfoYS: []
    })
    watch(
      () => props.patientData.patient_id,
      (val) => {
        if (val) {
          state.searchData = {
            patient_id: val,
            record_id: props.patientData.record_id,
          }
        } else {
          state.searchData = {
            patient_id: 0,
            record_id: 0,
          }
        }
        getDataList()
      }
    )
    onMounted(() => {
      if (props.patientData.patient_id) {
        state.searchData = {
          patient_id: props.patientData.patient_id,
          record_id: props.patientData.record_id,
        }
      } else {
        state.searchData = {
          patient_id: 0,
          record_id: 0,
        }
      }
      getDataList()
      getDict()
      getUserList()
      getDoctorUserList()
    })
    const getUserList = async () => {
      state.userNameList = [JSON.parse(localStorage.getItem('ms_userData'))]
    }
    const getDoctorUserList = async () => {
      state.doctorUserList = [JSON.parse(localStorage.getItem('ms_userData'))]
    }
    const changeValue = (code, name, type) => {
      if (type == 1) {
        state.ruleForm[code] = state.doctorUserList[0].id
        state.ruleForm[name] = state.doctorUserList[0].name
      } else {
        state.ruleForm[code] = ''
        state.ruleForm[name] = ''
      }
    }
    const refreshData = () => {
      getDataList()
      ctx.emit('stepsUpdate')
    }
    const getDataList = async () => {
      state.userInfoHS = []
      state.userInfoYS = []
      state.loadings = true
      let res = await service.post('api/estimate/summaryList', state.searchData)
      state.loadings = false
      if (res.code === 0) {
        if (!Array.isArray(res.data.array)) {
          state.ruleForm = res.data.array
          state.machine_status = res.data.machine_status
          setWeight()
          if (state.ruleForm.zeren_id != "" && state.ruleForm.zeren_id != state.userNameList[0].id) {
            let obj = {
              id: res.data.array.zeren_id,
              name: res.data.array.zeren_id_name
            }
            state.userInfoHS.push(obj, ...state.userNameList)
          } else {
            state.userInfoHS = state.userNameList
          }
          console.log(state.userInfoHS)
          if (state.ruleForm.doctor_id != "" && state.ruleForm.doctor_id != state.doctorUserList[0].id) {
            let obj = {
              id: res.data.array.doctor_id,
              name: res.data.array.doctor_id_name
            }
            state.userInfoYS.push(obj, ...state.userNameList)
          } else {
            state.userInfoYS = state.userNameList
          }
          console.log(state.userInfoYS)
          // Afterthoroughly()
          // getAutograph(state.ruleForm)
        }
      }
    }
    const getDict = async () => {
      state.dictList = await gitDictList([
        '178000000',
        '179000000',
        '193000000',
        '195000000',
        '196000000',
        '197000000',
        '202000000',
        '200000000',
        '228000000',
        '204000000',
        '630000000',
        '650000000',
        '640000000',
      ])
    }
    const ruleForm = (formEl) => {
      let flag = false
      if (!state[formEl]) return true
      state[formEl].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    }
    const setWeight = () => {
      // 实际透后体重 = 透后体重-净体重
      state.ruleForm.after_clean_weight = (
        state.ruleForm.after_weight - state.ruleForm.add_weight
      ).toFixed(2)
      // 实际脱水量 = 透前体重-透后体重 + 回血量
      // https://www.tapd.cn/44242100/prong/stories/view/1144242100001003009
      // 实际脱水=机显脱水-总输液量-回水量
      if (state.ruleForm.screen_actual_warter != 0) {
        state.ruleForm.real_water = (
          parseFloat(state.ruleForm.screen_actual_warter ? state.ruleForm.screen_actual_warter : 0) -
          parseFloat(state.ruleForm.total_flush_water ? state.ruleForm.total_flush_water : 0) -
          parseFloat(state.ruleForm.xj_huiwater ? state.ruleForm.xj_huiwater : 0)
        ).toFixed(2)
      }
      // 差 = 目标脱水 - 实际脱水
      state.ruleForm.differ = (
        parseFloat(state.ruleForm.target_water) - parseFloat(state.ruleForm.real_water)
      ).toFixed(2) / 1000
    }

    const weightInput = (val) => {
      val = val.replace(/[^\d.]/g, '')
      state.ruleForm.after_weight = state.ruleForm.after_weight.replace(
        /[^\d.]/g,
        ''
      )
      if (state.ruleForm.befor_weight <= 0 || !val) {
        state.ruleForm.after_clean_weight = ''
        state.ruleForm.befor_clean_weight = ''
        state.ruleForm.differ = ''
        return
      }
      setWeight()
    }
    const estimateSave = async () => {
      if (!state.searchData.patient_id) {
        ElMessageBox.alert('请先选择患者', '提示', {
          confirmButtonText: '确认',
        })
        return
      }
      if (!state.searchData.record_id) {
        ElMessageBox.alert(
          '当前患者的透析治疗记录还没保存，不能保存透后小结！',
          '提示',
          {
            confirmButtonText: '确认',
          }
        )
        return
      }
      state.ruleForm.patient_id = state.searchData.patient_id
      state.ruleForm.record_id = state.searchData.record_id
      let res = await service.post('api/estimate/summary', state.ruleForm)
      if (res.code === 0) {
        state.pdfURL = res.data.report_pdf_url
        state.isPdf = true
        ElMessage.success(res.msg)
        ctx.emit('stepsUpdate')
        // getAutograph(state.ruleForm)
      }
      // }
    }
    // 生成小结
    const generateSummary = async () => {
      if (!state.searchData.record_id && state.searchData.record_id === 0) {
        ElMessage({
          message: '请先添加透析记录',
          type: 'warning',
        })
        return
      }
      state.ruleForm.patient_id = state.searchData.patient_id
      state.ruleForm.record_id = state.searchData.record_id
      state.saveLoading = true
      let res = await service.post('api/estimate/createSummary', state.ruleForm)
      state.saveLoading = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        state.ruleForm.summary = res.data.tem_show
        // if (state.ruleForm.summary) {
        //   state.ruleForm.summary += '\n' + res.data.tem_show
        // } else {
        //   state.ruleForm.summary += res.data.tem_show
        // }
      }
    }

    const openTemplate = (type) => {
      if (!state.searchData.record_id && state.searchData.record_id === 0) {
        ElMessage({
          message: '请先添加透析记录',
          type: 'warning',
        })
        return
      }
      state.bodyvlus = true
      getSummaryTemplate(type)
    }
    // 模板列表
    const getSummaryTemplate = async (type) => {
      state.searchDatas.current = state.pageData.currentPage
      state.searchDatas.size = state.pageData.pageSize
      state.searchDatas.type = type
      state.tableLoading = true
      let res = await service.post(
        'api/estimate/summaryTemplate',
        state.searchDatas
      )
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.template
        state.pageData.totalSum = res.data.total
      }
    }
    const useTemplate = (item) => {
      if (item.type == 1) {
        if (state.ruleForm.summary) {
          state.ruleForm.summary += "\n" + item.tem_show
        } else {
          state.ruleForm.summary += item.tem_show
        }
      } else {
        if (state.ruleForm.doctor_summary) {
          state.ruleForm.doctor_summary += "\n" + item.tem_show
        } else {
          state.ruleForm.doctor_summary += item.tem_show
        }
      }
      state.bodyvlus = false
    }
    const disinfectRecordOpen = () => {
      state.isViewDialog = true
      nextTick(() => {
        state.disinfectRecordRef.initData(state.searchData)
      })
    }
    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getSummaryTemplate()
    }
    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getSummaryTemplate()
    }

    const handlePipeDrugChange = async(e) => {
      var postData = {
        id: props.patientData.record_id,
        patient_id: props.patientData.patient_id,
        pipe_drug: e
      }
      let res = await service.post(
          'api/estimate/getLastTimePipeDrugInfo',
          postData
      )
      if (res.code === 0) {
        state.ruleForm.dongmai_val = res.data.dongmai_val
        state.ruleForm.jingmai_val = res.data.jingmai_val
      }
    }

    return {
      ...toRefs(state),
      estimateSave,
      generateSummary,
      openTemplate,
      useTemplate,
      handleSizeChange,
      handleCurrentChange,
      disinfectRecordOpen,
      getDataList,
      refreshData,
      weightInput,
      setWeight,
      changeValue,
      handlePipeDrugChange
    }
  },
}
</script>

<style scoped lang="scss">
.cont-main {
  flex: 1;

  // max-height: 600px;
  // overflow-y: auto;
  // overflow-x: hidden;
  .el-form {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
  }

  .el-form-item {
    width: 30%;
    margin-right: 5%;

    &.addr {
      width: 100%;
      margin-right: 0;

      &.but {
        width: calc(100% - 100px);
      }
    }
  }

  .templateBut {
    width: 88px;
    margin-left: 12px;
    display: inline-block;
    position: relative;
    top: 7px;

    .tip {
      margin-left: 0;
      margin-top: 6px;
    }
  }

  .el-form-item:nth-child(3n + 3) {
    margin-right: 0;
  }

  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;

    .user-tit {
      font-size: 24px;
      font-family: 'Source Han Sans CN-Medium';
      color: #333333;
      border-left: 6px solid #3166ae;
      padding: 0 18px;
      margin-bottom: 40px;
    }
  }
}

.title {
  margin-bottom: 20px;

  .jniop {
    width: 6px;
    height: 18px;
    background: #3166ae;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    margin-top: 4px;
    margin-right: 10px;
  }

  .juliss {
    height: 18px;
    font-size: 18px;
    color: #333333;
    margin-left: 10px;
  }
}

.fiall {
  display: flex;
  position: fixed;
  bottom: 5%;
  right: 5%;
}

.warings {
  width: 60px;
  height: 60px;
  background-color: #13c2c2;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 20px;
  cursor: pointer;
}

.pdfBox {
  width: 100%;
  height: 70vh;
}
</style>
